/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import Logo from 'components/assets/lifepoints-logo.svg';
import A from 'components/atoms/A/A';
import LabelledIcon from 'components/atoms/LabelledIcon/LabelledIcon';
import Txt from 'components/atoms/Txt/Txt';
import { DASHBOARD } from 'components/pages/pages';
import {
  AuthenticationContext,
  useIsLoggedIn,
  useUserJwtData,
} from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import useExternalLink from 'hooks/useExternalLink';
import useOnePConfiguration from 'hooks/useOnePConfiguration';
import React, { MouseEvent, useContext } from 'react';
import { MdAccountCircle, MdAssignmentTurnedIn, MdGroup, MdHelp, MdRedeem } from 'react-icons/md';
import classes from './Header.module.scss';
import HeaderDropDown from './HeaderDropdown';
import ExternalLink from 'components/atoms/ExternalLink/ExternalLink';

export interface Props {
  theme?: string | string[];
  openSidebar?: Function;
}

const Header: React.FC<Props> = ({ theme, openSidebar }) => {
  const isLoggedIn = useIsLoggedIn();
  const { logOut } = useContext(AuthenticationContext);
  const { country } = useUserJwtData() || { country: '' };
  const isUcmEnabled: boolean = useOnePConfiguration(country, 'ucm_allowed') || false;

  function handleLogout(e: MouseEvent) {
    e.preventDefault();
    logOut('header');
  }

  // handles the clicking of the Profile nav button in mobile view to open the sidebar menu
  function handleProfileMenu(e: MouseEvent) {
    e.preventDefault();
    if (typeof openSidebar === 'function') {
      openSidebar();
    }
  }

  // The navbar should not be displayed when the user is not logged in.
  const navBar = (
    <nav className={classes.navbar}>
      <LabelledIcon label="Surveys" href="/dashboard">
        <MdAssignmentTurnedIn />
      </LabelledIcon>
      <LabelledIcon label="Rewards" href="/rewards">
        <MdRedeem />
      </LabelledIcon>
      <LabelledIcon
        label="Community"
        link="community"
        href="https://www.lifepointspanel.com/community"
      >
        <MdGroup />
      </LabelledIcon>
      <LabelledIcon label="Help Center" className={classes.dropdownOnHover}>
        <MdHelp />
        <HeaderDropDown className={classes.dropdownOnHover}>
          <A href={'/member/zendeskhelp/helpcenter'}>
            <Txt>FAQ</Txt>
          </A>
          <ExternalLink link="survey" href="https://www.lifepointspanel.com/survey-tips">
            <Txt component="figcaption" className={classes.extLinkStyle}>
              Survey Tips
            </Txt>
          </ExternalLink>
          <A href={'/member/zendeskhelp/requests'}>
            <Txt>My Requests</Txt>
          </A>
        </HeaderDropDown>
      </LabelledIcon>
      <LabelledIcon label="Account" className={classes.dropdownOnHover}>
        <MdAccountCircle />
        <HeaderDropDown className={classes.dropdownOnHover}>
          <A href={'/member/account'}>
            <Txt>My Profile</Txt>
          </A>
          <A href={'/member/privacy-settings'}>
            <Txt>Privacy Settings</Txt>
          </A>
          {isUcmEnabled ? (
            <A href={'/member/data-preferences'}>
              <Txt>Data Usage & Preferences</Txt>
            </A>
          ) : (
            <></>
          )}
          <A href={'/member/contact-preferences'}>
            <Txt>Contact Preferences</Txt>
          </A>
          <A href={'#'} onClick={handleLogout} className={classes.signout}>
            <Txt>Sign Out</Txt>
          </A>
        </HeaderDropDown>
      </LabelledIcon>
    </nav>
  );

  // The profile menu only shows for mobile view
  const mobileMenu = (
    <div className={classes.dashboardMenu}>
      <LabelledIcon href={'#'} onClick={handleProfileMenu} className={classes.mobileMenuBtn}>
        <MdAccountCircle />
      </LabelledIcon>
    </div>
  );

  // There should always be a url for 'home', but in case it returns null set to empty string.
  const homePageUrl = useExternalLink('home') || '';
  const logoUrl = (isLoggedIn && DASHBOARD.path) || homePageUrl;
  return (
    <header id="page-header" className={clsx(classes.root, 'hidden-when-embedded')}>
      <div className={classes.upperHeaderContainer}>
        <div className={classes.upperHeader}>
          <A href={logoUrl}>
            <Logo id="logo" className={classes.logo} width={150} height={50} />
          </A>
          {isLoggedIn ? navBar : null}
          {isLoggedIn ? mobileMenu : null}
        </div>
      </div>
    </header>
  );
};

const MemoizedHeader = React.memo(Header);
export default MemoizedHeader;
