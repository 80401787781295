import {
  getCountryData,
  getEmojiFlag,
  ICountryData,
  ILanguage,
  languages,
  TCountryCode,
  TLanguageCode,
} from 'countries-list';

export type LocaleInfo = {
  locale: string;
  language: ILanguage;
  country?: ICountryData;
  countryEmoji?: string;
};

const LOCALE_REGEX = /^(?<language>[A-Za-z]+)(-(?<country>[A-Za-z]+))?$/;

export function getLocaleInfo(locale: string): LocaleInfo | null {
  const match = locale.match(LOCALE_REGEX);
  const languageCode = match?.groups?.language as TLanguageCode | undefined;
  const countryCode = match?.groups?.country as TCountryCode | undefined;

  const country = countryCode && getCountryData(countryCode);
  const language = languageCode && languages[languageCode];
  return language
    ? {
        locale,
        country,
        language,
        countryEmoji: countryCode && getEmojiFlag(countryCode),
      }
    : null;
}
