import React, { ReactNode, useContext } from 'react';

type Context = {
  namespace?: string;
};

export const TranslateContext = React.createContext<Context>({
  namespace: undefined,
});

export type Props = {
  children: ReactNode;
  namespace: string;
};
export default function TranslationWrapper({ children, namespace }: Props) {
  return <TranslateContext.Provider value={{ namespace }}>{children}</TranslateContext.Provider>;
}

export function useNamespace() {
  return useContext(TranslateContext).namespace;
}
