import React from 'react';
import classes from './HeaderDropdown.module.scss';
import clsx from 'clsx';
import { Props as AProps } from 'components/atoms/A/A';

export interface Props {
  children?: React.ReactElement<AProps>[];
  className?: string;
}

const HeaderDropDown: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <ul className={clsx(classes.root, className)}>
      {children?.map((child, index) => {
        return <li key={index}>{child}</li>;
      })}
    </ul>
  );
};

export default HeaderDropDown;
