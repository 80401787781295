import clsx from 'clsx';
import A from 'components/atoms/A/A';
import { useNamespace } from 'components/templates/Translation/TranslationWrapper';
import { Trans, useTranslation } from 'next-i18next';
import React, {
  AnchorHTMLAttributes,
  ClassAttributes,
  FunctionComponent,
  ReactElement,
} from 'react';
import ReactMarkdown from 'react-markdown';
import classes from './Txt.module.scss';

export type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];

interface BaseProps<C extends React.ElementType> {
  component?: C;
  children: string;
  className?: string;
  skipTranslation?: boolean;
  bold?: boolean;
  size?: 'small' | 'normal' | 'large' | 'xlarge' | 'xxlarge';
  color?: 'primary' | 'secondary' | 'default';
  asMarkdown?: boolean;
}

const MarkdownLink: FunctionComponent<
  ClassAttributes<HTMLAnchorElement> & AnchorHTMLAttributes<HTMLAnchorElement>
> = function ({ href, children }) {
  return (
    <A href={href?.toString() ?? '/missingpath'} target="_blank" rel="noopener noreferrer">
      {children}
    </A>
  );
};

export type Props<C extends React.ElementType> = BaseProps<C> &
  Omit<React.ComponentPropsWithRef<C>, keyof BaseProps<C>>;

const Txt = <C extends React.ElementType = 'span'>(
  {
    children,
    component,
    skipTranslation,
    translateOptions,
    color,
    size,
    bold,
    asMarkdown,
    ...rest
  }: Props<C>,
  ref: PolymorphicRef<C>,
): ReactElement => {
  const namespace = useNamespace();
  const { t } = useTranslation(namespace);

  const className = clsx(
    rest.className,
    color && classes[color],
    size && classes[size],
    bold && classes.bold,
    asMarkdown && classes.markdown,
  );
  const Root = component || 'span';

  return (
    <Root {...rest} className={className} ref={ref}>
      {asMarkdown ? (
        <ReactMarkdown components={{ a: MarkdownLink }}>
          {skipTranslation ? children : t(children)}
        </ReactMarkdown>
      ) : skipTranslation ? (
        children
      ) : (
        <Trans t={t}>{children}</Trans>
      )}
    </Root>
  );
};
export default React.memo(React.forwardRef(Txt));
