import NextImage, { ImageLoader, ImageProps } from 'next/image';
import { useRouter } from 'next/router';

export type Props = {} & Omit<ImageProps, 'loader'>;

export default function LocaleAwareImage(props: Props) {
  const { locale } = useRouter();

  /**
   * Custom image loader to support locale-aware images.
   * We can't efficiently check the locale-specific images exist within the component, so we need to do it in an API handler.
   */
  const imageLoader: ImageLoader = ({ src, width, quality }) => {
    return `${
      process.env.NEXT_PUBLIC_ASSET_PREFIX || ''
    }/api/locales/${locale?.toLowerCase()}/image?url=${encodeURIComponent(src)}&w=${width}&q=${
      quality || 75
    }`;
  };
  return <NextImage loader={imageLoader} {...props} />;
}
