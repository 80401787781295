import useIsSsr from 'hooks/useIsSsr';
import useJoinApiUserDetails from 'hooks/useJoinApiDetails';
import useLocalStorageState from 'hooks/useLocalStorageState';
import useUserSourceData from 'hooks/useUserSourceData';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React from 'react';
import {
  fetchNewTokens,
  fetchOnePToken,
  getUserDataFromToken,
  isTokenExpired,
  logOut,
  RegistrationData,
  submitLogin,
  submitSignUp,
} from './authApiUtils';
import {
  AuthenticationContextValue,
  AuthenticationTokenState,
  EmbeddedToken,
  LoginWithOtp,
  LoginWithPassword,
  ONEPTOKEN_STORAGE_KEY,
  SignUp,
  TOKENS_STORAGE_KEY,
  UpdateTokens,
  UserData,
} from './Types';
import { useOtpLogin } from './useOtpLogin';

export const DEFAULT_AUTHENTICATION_CONTEXT: AuthenticationContextValue = {
  signUp: () => Promise.resolve(null),
  login: () => Promise.resolve(null),
  loginWithOtp: () => Promise.resolve(null),
  logOut: () => {},
  updateTokens: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(null),
  refreshToken: null,
  accessToken: null,
  onePToken: null,
  isLoggedIn: false,
  userData: null,
};
export const AuthenticationContext = React.createContext<AuthenticationContextValue>(
  DEFAULT_AUTHENTICATION_CONTEXT,
);

export interface Props {
  children: React.ReactNode;
}

const AuthenticationProvider: React.FC<Props> = ({ children }) => {
  const router = useRouter();
  const { pathname, query, locale } = router;
  const [localStorageTokens, setTokens] = useLocalStorageState<
    AuthenticationTokenState | EmbeddedToken
  >(TOKENS_STORAGE_KEY, null);

  const tokens = React.useMemo(
    () =>
      localStorageTokens ||
      (query?.accessToken && ({ accessToken: query.accessToken } as EmbeddedToken)) ||
      null,
    [localStorageTokens, query?.accessToken],
  );
  const tokensRef = React.useRef(tokens);

  tokensRef.current = tokens; // tokens can change if another tab is updating them. We need to make sure to keep the ref up to date

  const invalidUserTimeRef = React.useRef(false);
  const fetchFreshTokens = React.useRef<Promise<AuthenticationTokenState> | null>(null);
  const userSourceData = useUserSourceData();
  const [onePToken, setOnePToken] = useLocalStorageState<string>(ONEPTOKEN_STORAGE_KEY, '');

  const getOnePToken = React.useCallback(async (accessToken: string, locale: string) => {
    // If the access token is empty, don't create a onep token
    if (accessToken === '') return '';
    const t = await fetchOnePToken(accessToken, locale);
    return t.jwt;
  }, []);

  const handleOnePTokenUpdated = React.useCallback(
    (updatedOnePToken: string) => {
      setOnePToken(updatedOnePToken);
    },
    [setOnePToken],
  );

  const handleTokensUpdated = React.useCallback(
    (updatedTokens: AuthenticationTokenState | EmbeddedToken) => {
      tokensRef.current = updatedTokens;
      if (updatedTokens) {
        if (isTokenExpired(updatedTokens.accessToken)) {
          // Prevent us from being DOSed if a users time is incorrect
          invalidUserTimeRef.current = true;
        }
        getOnePToken(updatedTokens?.accessToken, locale ?? 'en-us').then((jwt) => {
          handleOnePTokenUpdated(jwt);
        });
        document.documentElement.setAttribute('data-logged-in', 'true');
      } else {
        localStorage.removeItem(TOKENS_STORAGE_KEY);
        document.documentElement.setAttribute('data-logged-in', 'false');
        handleOnePTokenUpdated('');
      }
      // We need to make sure to update the reference before setting the tokens and starting to render the logged in part of the website/fetching data
      setTokens(updatedTokens);
      tokensRef.current = tokens; // tokens can change if another tab is updating them. We need to make sure to keep the ref up to date
    },
    [getOnePToken, handleOnePTokenUpdated, setTokens, locale],
  );

  const { doiToken } = useJoinApiUserDetails();
  const handleSignUp = React.useCallback(
    (registrationData: RegistrationData) =>
      submitSignUp({ locale }, registrationData, userSourceData, doiToken).then((t) => {
        handleTokensUpdated(t);
        return getUserDataFromToken(t?.accessToken);
      }),
    [doiToken, handleTokensUpdated, locale, userSourceData],
  );

  const handleLogin = React.useCallback(
    (email: string, password: string) =>
      submitLogin({ locale }, email, password).then((t) => {
        handleTokensUpdated(t);
        return getUserDataFromToken(t?.accessToken);
      }),
    [handleTokensUpdated, locale],
  );

  const handleOtpLogin = useOtpLogin(handleTokensUpdated, Boolean(tokens?.accessToken));

  const handleLogOut = React.useCallback(
    (trigger: string | null = null) => {
      logOut({ locale }, tokensRef.current?.refreshToken, trigger || 'unknown').then(() => {
        handleTokensUpdated(null);
      });
    },
    [handleTokensUpdated, locale],
  );

  // If the user is logged out but has a logged_in cookie something has gone wrong and we should clear the cookies by doing a logout
  React.useEffect(() => {
    if (!tokensRef.current && Cookies.get('logged_in')) {
      // If the logged in cookie still exists without the tokens in the local storage we want to clean up all cookies by logging the user out
      Cookies.remove('logged_in');
      handleLogOut('invalid-logged_in-cookie');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // It is important that this callback reference is never updated otherwise relay will create a new ENV and refetch all data
  const getAccessToken = React.useCallback(
    async (forceReloadReason: string | null = null) => {
      if (
        forceReloadReason ||
        (!invalidUserTimeRef.current &&
          tokensRef.current &&
          isTokenExpired(tokensRef.current.accessToken)) ||
        fetchFreshTokens.current
      ) {
        // If we are already fetching a new token we want to wait for the existing request instead
        fetchFreshTokens.current =
          fetchFreshTokens.current ||
          (tokensRef.current?.refreshToken &&
            fetchNewTokens(
              { locale },
              tokensRef.current?.refreshToken,
              forceReloadReason || 'expired',
              // If we used a first party cookie before, we want to keep using the new auth-session domain. This can be removed after 30 days once all non migrated tokens have been migrated
              tokensRef.current.firstPartyCookie || false,
            ).catch((err) => {
              // eslint-disable-next-line no-console
              console.error('Token refetch error', err);
              if (window.newrelic) window.newrelic.noticeError(err as Error);
              fetchFreshTokens.current = null;
              if (err.status === 401) {
                handleLogOut('auth-401');
              }
              return Promise.resolve(null);
            })) ||
          Promise.resolve(null);
        const freshTokens = await fetchFreshTokens.current;
        fetchFreshTokens.current = null;
        handleTokensUpdated(freshTokens);
        return freshTokens?.accessToken || null;
      }
      return tokensRef.current?.accessToken || null;
    },
    [handleLogOut, handleTokensUpdated, locale],
  );

  React.useEffect(() => {
    if (tokens?.accessToken) {
      // Force the accessToken being refreshed in case it has expired
      getAccessToken();
    }
  }, [tokens, getAccessToken, query?.accessToken]);

  React.useEffect(() => {
    if (query?.accessToken) {
      const token = Array.isArray(query.accessToken) ? query.accessToken[0] : query.accessToken;
      handleTokensUpdated({ accessToken: token });
      const params = new URLSearchParams(query as Record<string, string>);
      params.delete('accessToken');
      router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
    }
  }, [handleTokensUpdated, query?.accessToken]);

  React.useEffect(() => {
    if (tokens?.accessToken && !tokens.refreshToken) {
      document.documentElement.setAttribute('data-embedded', 'true');
    } else {
      document.documentElement.setAttribute('data-embedded', 'false');
    }
  }, [tokens]);

  const contextValue = React.useMemo<AuthenticationContextValue>(
    () => ({
      signUp: handleSignUp,
      getAccessToken,
      refreshToken: tokens?.refreshToken || null,
      accessToken: tokens?.accessToken || null,
      onePToken: onePToken || null,
      login: handleLogin,
      loginWithOtp: handleOtpLogin,
      updateTokens: handleTokensUpdated,
      isLoading: handleLogin,
      isLoggedIn: Boolean(tokens?.accessToken),
      logOut: handleLogOut,
      userData: getUserDataFromToken(tokens?.accessToken),
    }),
    [
      tokens,
      onePToken,
      handleSignUp,
      handleLogin,
      handleOtpLogin,
      handleLogOut,
      getAccessToken,
      handleTokensUpdated,
    ],
  );
  return (
    <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>
  );
};

// hooks and provider exports
export default AuthenticationProvider;

export function useSignUp(): SignUp {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.signUp;
}

export function useAccessToken(): (reason?: string) => Promise<string | null> {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.getAccessToken;
}

export function useRefreshToken(): string | null {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.refreshToken;
}

export function useActualAccessToken(): string | null {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.accessToken;
}

export function useIsLoggedIn(): boolean {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  const isSsr = useIsSsr();
  return context.isLoggedIn && !isSsr;
}

export function useLogin(): LoginWithPassword {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.login;
}

export function useLoginWithOtp(): LoginWithOtp {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.loginWithOtp;
}

export function useUpdateTokens(): UpdateTokens {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.updateTokens;
}

export function useUserJwtData(): UserData | null {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  const isSsr = useIsSsr();

  return isSsr ? null : context.userData;
}

export function useActualOnePToken(): string | null {
  const context = React.useContext<AuthenticationContextValue>(AuthenticationContext);
  return context.isLoggedIn ? context.onePToken : null;
}
