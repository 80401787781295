import React, { ReactElement, ReactNode } from 'react';
import classes from './Heading.module.scss';
import clsx from 'clsx';
import Txt from '../Txt/Txt';

interface HeaderStyleProps {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  color?: 'default' | 'inverse';
}

export type Props<T extends React.ElementType = 'h1'> = {
  children: ReactNode;
  className?: string;
  skipTranslation?: boolean;
  asMarkdown?: boolean;
} & React.ComponentProps<T> &
  HeaderStyleProps;

function Heading<T extends React.ElementType = 'h1'>({
  children,
  skipTranslation,
  translateOptions,
  level,
  color,
  asMarkdown,
  ...rest
}: Props<T>): ReactElement {
  level = level || 1;

  const className = clsx(rest.className, level && classes['h' + level], color && classes[color]);

  const Root = `h${level}` as React.ElementType;
  return (
    <Txt
      {...rest}
      className={className}
      component={Root}
      asMarkdown={asMarkdown}
      skipTranslation={skipTranslation}
    >
      {children}
    </Txt>
  );
}

export default React.memo(Heading);
