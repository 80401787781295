import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { IconType } from 'react-icons';
import A from '../A/A';
import ExternalLink from '../ExternalLink/ExternalLink';
import Txt from '../Txt/Txt';
import classes from './LabelledIcon.module.scss';

interface LabelledIconStyleProps {
  size?: 'small' | 'normal' | 'large' | 'xlarge' | 'xxlarge';
  color?: 'primary' | 'secondary' | 'default';
  label?: string;
  href?: string;
  link?: string;
}

export type Props<T extends React.ElementType = 'figure'> = {
  children: IconType;
  className?: string;
} & React.ComponentProps<T> &
  LabelledIconStyleProps;

function LabelledIcon<T extends React.ElementType = 'figure'>({
  children,
  color,
  size,
  label,
  href,
  link,
  ...rest
}: Props<T>): ReactElement {
  // Check if the current href is also the current path
  const router = useRouter();
  const isActive = router.pathname === href?.toString();

  const className = clsx(
    classes.root,
    rest.className,
    color && classes[color],
    size && classes[size],
    isActive && classes.active,
  );

  // If there is a link attribute, use the ExternalLink component,
  // otherwise use the A component.
  let tag = null;
  if (typeof link !== 'undefined' && link.length) {
    tag = (
      <ExternalLink link={link} href={href}>
        {children}
        <Txt component="figcaption">{label}</Txt>
      </ExternalLink>
    );
  } else if (href) {
    tag = (
      <A href={href}>
        {children}
        <Txt component="figcaption">{label}</Txt>
      </A>
    );
  } else {
    tag = (
      <div className={classes.dropdownWrapper}>
        {children}
        <Txt component="figcaption">{label}</Txt>
      </div>
    );
  }

  return (
    <figure {...rest} className={className}>
      {tag}
    </figure>
  );
}

export default React.memo(LabelledIcon);
