import useQueryStringParams from 'hooks/useQueryStringParams';
import useStorage from 'hooks/useStorage';
import { useEffect } from 'react';

type JoinApiUserDetails = {
  doiToken?: string;
  contactEmail?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  streetAddress2?: string;
  contactCity?: string;
  state?: string;
  lang?: string;
  country?: string;
};

export default function useJoinApiUserDetails(): JoinApiUserDetails {
  const params = useQueryStringParams();
  const [joinApiUserDetails, setJoinApiUserDetails] = useStorage<JoinApiUserDetails>(
    'join-api-user-details',
    {},
  );

  useEffect(() => {
    params.doi_token && setJoinApiUserDetails({ doiToken: params.doi_token });
  }, [setJoinApiUserDetails, params.doi_token]);

  return joinApiUserDetails;
}
