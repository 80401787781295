import AuthenticationProvider from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import PageContainer from 'components/templates/PageContainer/PageContainer';
import RelayProvider from 'components/templates/RelayProvider';
import TranslationWrapper from 'components/templates/Translation/TranslationWrapper';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router.js';
import React, { useEffect } from 'react';
import nextI18NextConfig from '../../next-i18next.config.js';
import '../styles/globals.scss';
import '../styles/lifepoints.scss';

export function App({ Component, pageProps }: AppProps) {
  const { locale, query } = useRouter();
  const { ready } = useTranslation(['common']);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    if (query.he) {
      window.dataLayer.push({
        'gaw-ecid': query.he.toString(),
      });
    }
    if (query.doi) {
      window.dataLayer.push({
        event: 'arrivedFromDoi',
      });
    }
  }, [query]);

  return (
    <React.StrictMode>
      <React.Suspense>
        <AuthenticationProvider>
          <React.Suspense>
            <RelayProvider locale={locale}>
              {ready && (
                <PageContainer
                  withoutHeader={pageProps.withoutHeader}
                  withoutFooter={pageProps.withoutFooter}
                >
                  <React.Suspense>
                    <TranslationWrapper namespace={pageProps.namespace}>
                      <Component {...pageProps} />
                    </TranslationWrapper>
                  </React.Suspense>
                </PageContainer>
              )}
            </RelayProvider>
          </React.Suspense>
        </AuthenticationProvider>
      </React.Suspense>
    </React.StrictMode>
  );
}

export default appWithTranslation(App, nextI18NextConfig as any);
