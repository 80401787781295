import { AllowedStorageKey } from 'constants/persistentStorageRegistry';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MemoryStorage from 'utils/MemoryStorage';

export type Options = {
  storeInitialValue?: boolean;
  disableInstanceSync?: boolean;
  session?: boolean;
};

export default function useStorage<T>(
  storageKey: AllowedStorageKey,
  initialState: T | (() => T),
  options: Partial<Options> = {
    storeInitialValue: false,
    disableInstanceSync: false,
    session: true,
  },
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const storage: Storage = useMemo(() => {
    if (typeof window === 'undefined') {
      return new MemoryStorage();
    }
    return options.session ? window.sessionStorage : window.localStorage;
  }, [options.session]);

  const initialStateFactory = useCallback(() => {
    const existingState = storage.getItem(storageKey);
    if (existingState) {
      return JSON.parse(existingState);
    }
    const initialValue = initialState instanceof Function ? initialState() : initialState;
    if (options.storeInitialValue) {
      storage.setItem(storageKey, JSON.stringify(initialValue));
    }
    return initialValue;
  }, [options.storeInitialValue, initialState, storage, storageKey]);

  const [state, setState] = useState<T>(initialStateFactory);

  const storeState: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (newState: React.SetStateAction<T>) => {
      setState(newState);
      if (newState instanceof Function) {
        newState = newState(state);
      }
      const newValue = JSON.stringify(newState);
      if (storage.getItem(storageKey) !== newValue) {
        storage.setItem(storageKey, JSON.stringify(newState));
      }
    },
    [storage, storageKey],
  );

  useEffect(() => {
    if (!options.disableInstanceSync) {
      const updateState = () => {
        const updated = storage.getItem(storageKey);
        if (updated) {
          setState(JSON.parse(updated));
        }
      };
      window.addEventListener('storage', updateState);
      return () => window.removeEventListener('storage', updateState);
    }
    return () => {};
  }, [setState, storageKey, options.storeInitialValue, options.disableInstanceSync, storage]);

  return [state, storeState];
}
