export function convertSnakeToCamelCase(snake: string) {
  function firstUpper(str: string) {
    return str[0] ? str[0].toLocaleUpperCase() + str.substring(1) : str;
  }
  let prev: string;
  return snake
    .split('_')
    .map((str, i) => {
      const newStr = i === 0 || prev === '' ? str : firstUpper(str);
      prev = str;
      return newStr;
    })
    .join('');
}
