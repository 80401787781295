import {
  useUserJwtData,
  useActualOnePToken,
} from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import useOnePConfiguration, { ConfigEntry } from './useOnePConfiguration';
import { UserData } from 'components/templates/AuthenticationProvider/Types';

const CMS_DOMAIN = `${process.env.NEXT_PUBLIC_CMS_DOMAIN}`; //'lifepointspanel.com';

type ExternalLinkDefinition = {
  link: string;
  whitelist?: string[];
  blacklist?: string[];
  configuration?: string;
};

/**
 * External Links can be formatted as just a `name:'link'`, or can have
 * the additional object properties of:
 *  - a blacklist (string array of disallowed countries or locales)
 *  - a whitelist (string array of allowed countries or locales)
 *  - or a configuration setting in the form of `system:name` that
 *    returns a booleanish value (only `onep:` system supported now).
 *
 * In each case the boolean expression is evaluated on whether to return
 * the link or a null value, which indicates to not show the link.
 *   Blacklist > Whitelist > Configuration value.
 * See ExternalLinkDefinition type above.
 *
 * The link property can have the following replacement tokens:
 *  - {locale}: current user locale, based on country and OneP languages
 *               (see CMS_LANGUAGES map)
 *  - {country}: the current user country; only available for authenticated sessions
 *  - {onePToken}: current token used to create an authenticated OneP session
 */
const EXTERNAL_LINKS = {
  home: `https://${CMS_DOMAIN}{locale}/{onePToken}`,
  login: `https://${CMS_DOMAIN}{locale}/login{onePToken}`,
  community: `https://${CMS_DOMAIN}{locale}/community{onePToken}`,
  who: `https://${CMS_DOMAIN}{locale}/who-we-are{onePToken}`,
  members: `https://${CMS_DOMAIN}{locale}/our-members{onePToken}`,
  how: `https://${CMS_DOMAIN}{locale}/how-it-works{onePToken}`,
  terms: `https://${CMS_DOMAIN}{locale}/terms-of-service{onePToken}`,
  privacy: `https://${CMS_DOMAIN}{locale}/privacy{onePToken}`,
  cookies: `https://${CMS_DOMAIN}{locale}/cookies/more-information{onePToken}`,
  'reward-terms': `https://${CMS_DOMAIN}{locale}/terms-rewards{onePToken}`,
  'receive-lifepoints':
    'https://lifepoints.zendesk.com/hc{locale}/articles/360042087151-When-will-I-receive-LifePoints-',
  facebook: 'https://www.facebook.com/LifePointsPanel',
  twitter: 'https://www.twitter.com/lifepointspanel',
  instagram: 'https://www.instagram.com/lifepointspanel',
  'google-play': {
    link: 'https://play.google.com/store/apps/details?id=com.kantarprofiles.lifepoints',
    configuration: 'onep:android_app_banner',
  },
  'apple-store': {
    link: 'https://apps.apple.com/{country}/app/lifepoints-panel/id1508335904',
    configuration: 'onep:ios_app_banner',
  },
  help: `/member/zendeskhelp/helpcenter`,
  survey: `https://${CMS_DOMAIN}{locale}/survey-tips{onePToken}`,
  // Now available
  'my-requests': `/member/zendeskhelp/requests`,
};
export type ExternalLinkKey = keyof typeof EXTERNAL_LINKS;

// Languages available on the CMS.  The first in the array is the default.
const CMS_LANGUAGES = {
  AR: ['es'],
  AT: ['de'],
  AU: ['en'],
  BE: ['nl', 'fr'],
  BR: ['pt'],
  CA: ['en', 'fr'],
  CH: ['de', 'fr', 'it'],
  CL: ['es'],
  CN: ['zh'],
  CO: ['es'],
  CZ: ['cs'],
  DK: ['da'],
  FI: ['fi'],
  FR: ['fr'],
  DE: ['de'],
  ES: ['es'],
  GR: ['el'],
  GB: ['en'],
  HK: ['zh', 'en'],
  HU: ['hu'],
  IN: ['en', 'hi'],
  ID: ['id', 'en'],
  IE: ['en'],
  IT: ['it'],
  JP: ['ja'],
  KR: ['ko'],
  LB: ['en'],
  MY: ['ms', 'en'],
  MX: ['es'],
  NL: ['nl'],
  NO: ['no'],
  NZ: ['en'],
  PE: ['es'],
  PH: ['en'],
  PL: ['pl'],
  PT: ['pt'],
  RO: ['ro'],
  SE: ['sv'],
  SG: ['en'],
  TH: ['th'],
  TR: ['tr'],
  TW: ['zh'],
  US: ['en', 'es'],
  VN: ['vi'],
  ZA: ['en'],
};
type CmsLanguagesKey = keyof typeof CMS_LANGUAGES;
const CMS_DEFAULT_COUNTRY: CmsLanguagesKey = 'US';

// Configuration settings from useOnePConfiguration
let onePSettings: ConfigEntry = {};

// Given a link name, it will return a pre-determined url based on locale
// and current environment, or null - in which case the link shouldn't be displayed.
export default function useExternalLink(
  name: ExternalLinkKey,
  query?: object | null,
): string | null {
  const userData = useUserJwtData();

  // figure out current locale & market
  const country = determineCountry(userData);
  const locale = determineLocale(country);

  const token = useActualOnePToken();
  const onePToken = token && token.length ? '?jt=' + token : '?jt=..';

  // get the full set of config values first as a hook must be called non-conditionally
  onePSettings = useOnePConfiguration(country) || {};

  let link: string | null = null;
  if (typeof EXTERNAL_LINKS[name] === 'string') {
    // simple string link
    link = EXTERNAL_LINKS[name] as string;
  } else if (typeof EXTERNAL_LINKS[name] === 'object') {
    // link defintion with additional rules
    link = processRules(EXTERNAL_LINKS[name] as ExternalLinkDefinition, country, locale);
  } else {
    // having the link as null is an easy way to disable a link
    return null;
  }

  if (link === null) return null;

  // replace the placeholders in the url;
  // note that urls should always be lower case
  link = link
    .replace('{locale}', locale ? '/' + locale : '')
    .replace('{country}', country.toLowerCase())
    .replace('{onePToken}', onePToken);

  if (query) {
    link =
      link + (link.indexOf('?') > -1)
        ? '&'
        : '?' + new URLSearchParams(Object.entries(query)).toString();
  }

  return link;
}

// Returns the current country from the userData context propery.
function determineCountry(userData: UserData | null): string {
  let country = userData ? userData.country : '';

  return country;
}

// Returns the current locale, using the country as a hint, based on the
// languages available on the CMS.
export function determineLocale(country: string): string {
  // If not logged in and therefore no country is set, let OneP determine the country
  if (country == '') return '';

  const [lang, region] =
    typeof window !== 'undefined'
      ? window.navigator.language.split('-', 2)
      : ['en', CMS_DEFAULT_COUNTRY];

  // Get the possible languages available for the user's country (from their UserData)
  const possibleLanguages =
    country.toUpperCase() in CMS_LANGUAGES
      ? CMS_LANGUAGES[country.toUpperCase() as CmsLanguagesKey]
      : CMS_LANGUAGES[CMS_DEFAULT_COUNTRY];

  const selectedLanguage = possibleLanguages.includes(lang) ? lang : possibleLanguages[0];

  // For now, we will use the preferred language as the language with the country.
  return country ? selectedLanguage + '-' + country.toLowerCase() : selectedLanguage;
}

// Processes the white and black lists for the given link and country/locale, to check
// if there are any rules preventing their usage; for example, limiting the Mobile App links
// to supported markets only.
function processRules(
  linkDef: ExternalLinkDefinition,
  country: string,
  locale: string,
): string | null {
  const link: string = linkDef.link;

  // Prioritize a blacklist check.
  if (linkDef.blacklist && linkDef.blacklist.length) {
    // If the country isn't determined, consider the check failed as well.
    if ((country = '')) return null;
    if (linkDef.blacklist.includes(country) || linkDef.blacklist.includes(locale)) return null;
  }

  // Check the whitelist, if it exists.
  if (linkDef.whitelist && linkDef.whitelist.length) {
    // Fail if the definition whitelist does not include the country or locale
    // This includes if the country and locale are not determined
    if (!linkDef.whitelist.includes(country) && !linkDef.whitelist.includes(locale)) return null;
  }

  // Check for a configuration setting, if it exists.
  if (linkDef.configuration && linkDef.configuration.length) {
    if (!evaluateConfigurationSetting(linkDef.configuration, country, locale)) return null;
  }

  return link;
}

// Returns a boolean (true or false) from a configuration setting value if truthy or falsy.
// The setting name must be formatted `type:name`. Currently only supports onep settings.
function evaluateConfigurationSetting(setting: string, country: string, locale: string): boolean {
  const [system, name] = setting.split(':', 2);

  // check type of setting
  if (system === 'onep') {
    return onePSettings[name] as boolean;
  }

  return false;
}
