import useQueryStringParams from 'hooks/useQueryStringParams';
import useStorage from 'hooks/useStorage';
import { useEffect } from 'react';
import {
  extractUserSourceDataFromQueryString,
  UserSourceData,
} from 'utils/extractUserSourceDataFromQueryString';

export default function useUserSourceData(): UserSourceData {
  const params = useQueryStringParams();
  const [userSourceData, setUserSourceData] = useStorage<UserSourceData>('user-source-data', {});

  useEffect(() => {
    const newUserSourceData = extractUserSourceDataFromQueryString(params);

    if (params['~campaign'] && !userSourceData?.utmCampaign) {
      newUserSourceData.utmCampaign = params['~campaign'];
      newUserSourceData.utmSource = 'Facebook';
      newUserSourceData.utmMedium = 'paid advertising';
    }

    setUserSourceData(newUserSourceData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setUserSourceData,
    params.invite,
    params.utm_source,
    params.utm_medium,
    params.utm_campaign,
    params.utm_content,
    params.city,
    params.country_code,
    params.date,
    params.file_id,
    params.file_name,
    params.mobile_carrier,
    params.ran,
    params.referer,
    params.region_code,
    params.source,
    params.user_agent,
    params.advertiser_id,
    params.advertiser_ref,
    params.aff_click_id,
    params.aff_id,
    params.aff_sub,
    params.aff_sub2,
    params.aff_sub3,
    params.aff_sub4,
    params.aff_sub5,
    params.aff_unique1,
    params.aff_unique2,
    params.aff_unique3,
    params.aff_unique4,
    params.aff_unique5,
    params.affiliate_id,
    params.affiliate_name,
    params.affiliate_ref,
    params.offer_file_id,
    params.offer_id,
    params.offer_name,
    params.offer_ref,
    params.offer_url_id,
    params.transaction_id,
    params.XP_utm_source,
    params.XP_utm_medium,
    params.XP_utm_campaign,
    params.XP_utm_term,
    params.__func,
    params.contactEmail,
    params.title,
    params.firstName,
    params.lastName,
    params.streetAddress,
    params.streetAddress2,
    params.contactCity,
    params.state,
    params.lang,
    params.country,
    params.socialToken,
  ]);

  return userSourceData;
}
