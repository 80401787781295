import A from 'components/atoms/A/A';
import useExternalLink, { ExternalLinkKey } from 'hooks/useExternalLink';
import { ComponentPropsWithRef } from 'react';

export type Props = ComponentPropsWithRef<typeof A> & {
  link: string | '';
};

function ExternalLink({ className, link, href, children, ...rest }: Props) {
  // lookup and format the url from the static list.
  let url = useExternalLink(link as ExternalLinkKey);
  if (url !== null && url.length === 0) {
    // use the current href attribute if link was empty.
    url = href.toString();
  } else if (url === null) {
    // if the url is null from the useExternalLink method, we should not show the link element.
    url = '';
  }

  if (url === '') return <></>;

  return (
    <A className={className} href={url as string} {...rest}>
      {children}
    </A>
  );
}

export default ExternalLink;
