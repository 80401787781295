import clsx from 'clsx';
import LabelledIcon from 'components/atoms/LabelledIcon/LabelledIcon';
import React from 'react';
import { MdAssignmentTurnedIn, MdGroup, MdHelp, MdRedeem } from 'react-icons/md';
import classes from './LoggedInFooterMenu.module.scss';

export interface Props {
  className?: string;
}

const LoggedInFooterMenu: React.FC<Props> = ({ className }: Props) => {
  return (
    <div className={clsx(classes.root)}>
      <nav role="navigation" className={clsx(className)}>
        <LabelledIcon label="Surveys" href="/dashboard">
          <MdAssignmentTurnedIn />
        </LabelledIcon>
        <LabelledIcon label="Rewards" href="/rewards">
          <MdRedeem />
        </LabelledIcon>
        <LabelledIcon
          label="Community"
          link="community"
          href="https://www.lifepointspanel.com/community"
        >
          <MdGroup />
        </LabelledIcon>
        <LabelledIcon
          label="Help Center"
          link="help"
          href="https://www.lifepointspanel.com/member/zendeskhelp/helpcenter"
        >
          <MdHelp />
        </LabelledIcon>
      </nav>
    </div>
  );
};

export default LoggedInFooterMenu;
