import clsx from 'clsx';
import LifepointsIcon from 'components/assets/lifepoints-icon.svg';
import QuratedIcon from 'components/assets/qurated-logo-dark.svg';
import Button from 'components/atoms/Button/Button';
import ExternalLink from 'components/atoms/ExternalLink/ExternalLink';
import Heading from 'components/atoms/Heading/Heading';
import LocaleAwareImage from 'components/atoms/LocaleAwareImage/LocaleAwareImage';
import Txt from 'components/atoms/Txt/Txt';
import LanguagePicker from 'components/molecules/LanguagePicker/LanguagePicker';
import { useIsLoggedIn } from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import useCookieConsentStatus, {
  ConsentStatus,
} from 'components/templates/ThirdPartyTrackingProvider/useCookieConsentStatus';
import useExternalLink from 'hooks/useExternalLink';
import React, { MouseEvent } from 'react';
import { FaFacebookF, FaInstagram, FaXTwitter } from 'react-icons/fa6';
import classes from './Footer.module.scss';
import LoggedInFooterMenu from './LoggedInFooterMenu';

export interface Props {
  theme?: string | string[];
}

const Footer: React.FC<Props> = ({ theme }) => {
  const isLoggedIn = useIsLoggedIn();
  const Icon = theme === 'qurated' ? QuratedIcon : LifepointsIcon;
  const hasAndroid = !!useExternalLink('google-play');
  const hasiOS = !!useExternalLink('apple-store');
  const [, setConsentStatus] = useCookieConsentStatus();

  const footerLinks = (
    <div className={classes.footerContainer}>
      <div className={classes.privacy}>
        <div className={classes.footerLogoColumn}>
          <Icon width={70} height={80} className={classes.footerLogo} />
        </div>
        <div>
          <Heading level={5}>Our privacy guarantee</Heading>
          <Txt component="p">
            We go above and beyond to ensure your data is safe and secure with us.
          </Txt>
        </div>
      </div>
      <nav className={classes.aboutUs}>
        <Heading level={5}>About Us</Heading>
        <ExternalLink link="how" href="#">
          <Txt>How It Works</Txt>
        </ExternalLink>
        <ExternalLink link="members" href="#">
          <Txt>Our Members</Txt>
        </ExternalLink>
        <ExternalLink link="who" href="#">
          <Txt>Who We Are</Txt>
        </ExternalLink>
      </nav>
      <nav className={classes.needHelp}>
        <Heading level={5}>Need Help?</Heading>
        <ExternalLink link="help" href="#">
          <Txt>Help Center</Txt>
        </ExternalLink>
      </nav>
      <div className={clsx(classes.apps, (hasAndroid || hasiOS) && classes.showAppLinks)}>
        <Heading level={5}>Download Apps</Heading>
        <ExternalLink link="google-play" href="#" target="_blank">
          <LocaleAwareImage alt="" src="/assets/google-play.svg" width={168} height={50} />
        </ExternalLink>
        <ExternalLink link="apple-store" href="#" target="_blank">
          <LocaleAwareImage alt="" src="/assets/apple-store.svg" width={154} height={51} />
        </ExternalLink>
      </div>
      <div className={classes.connect}>
        <Heading level={5}>Connect with us</Heading>
        <div className={classes.socialMedia}>
          <ExternalLink link="facebook" href="#">
            <FaFacebookF />
          </ExternalLink>
          <ExternalLink link="twitter" href="#">
            <FaXTwitter />
          </ExternalLink>
          <ExternalLink link="instagram" href="#">
            <FaInstagram />
          </ExternalLink>
        </div>
      </div>
      <LanguagePicker className={classes.languagePicker} />
    </div>
  );
  const moreLinks = (
    <nav className={classes.moreLinks}>
      <ExternalLink link="terms" href="#">
        <Txt>Membership Terms & Conditions</Txt>
      </ExternalLink>
      <ExternalLink link="privacy" href="#">
        <Txt>Panel Privacy Policy</Txt>
      </ExternalLink>
      <ExternalLink link="cookies" href="#">
        <Txt>Cookies Policy</Txt>
      </ExternalLink>
      <Button
        buttonType="text"
        className={classes.cookiePreferencesButton}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setConsentStatus(ConsentStatus.UNKNOWN);
        }}
      >
        <Txt>Cookie Preferences</Txt>
      </Button>
      <ExternalLink link="reward-terms" href="#">
        <Txt>Rewards Program Terms</Txt>
      </ExternalLink>
      {/* <A href=":">Sitemap</A>  Sitemap link is in footer for logged-off users */}
    </nav>
  );
  const copyright = (
    <div className={classes.copyright}>
      <Txt component="p">
        © {{ copyright: new Date().getFullYear() }} LIFEPOINTS LifePoints is owned and operated by
        Lightspeed Research Limited, a Kantar Group company.
      </Txt>
    </div>
  );
  const footerMenu = <LoggedInFooterMenu />;

  return (
    <footer className={clsx(classes.root, 'hidden-when-embedded')}>
      {footerLinks}
      {moreLinks}
      {copyright}
      {isLoggedIn ? footerMenu : null}
    </footer>
  );
};

export default Footer;
