import useCookie from 'hooks/useCookie';
import useLocalStorageState from 'hooks/useLocalStorageState';
import { useCallback, useEffect, useMemo } from 'react';

export enum ConsentStatus {
  CONSENTED = 'CONSENTED',
  NOT_CONSENTED = 'NOT_CONSENTED',
  UNKNOWN = 'UNKNOWN',
}

const STORAGE_KEY = 'ThirdPartyTrackingProvider.allowTracking';
const CONSENT_COOKIE_NAME = '3rd_party_optimization';

export default function useCookieConsentStatus() {
  const defaultConsent =
    typeof window !== 'undefined' && 'globalPrivacyControl' in window.navigator
      ? window.navigator.globalPrivacyControl
        ? ConsentStatus.NOT_CONSENTED
        : ConsentStatus.CONSENTED
      : ConsentStatus.UNKNOWN;
  const [legacyStatus, setLegacyStatus] = useLocalStorageState<ConsentStatus>(
    STORAGE_KEY,
    defaultConsent,
  );
  const [crossDomainCookie, setCrossDomainCookie] = useCookie(CONSENT_COOKIE_NAME);

  const setConsentStatus = useCallback(
    (newConsentStatus: ConsentStatus) => {
      if (newConsentStatus === ConsentStatus.UNKNOWN) {
        setCrossDomainCookie(undefined);
      } else {
        setCrossDomainCookie(newConsentStatus === ConsentStatus.CONSENTED ? 'true' : 'false');
      }
    },
    [setCrossDomainCookie],
  );

  useEffect(() => {
    if (crossDomainCookie === undefined && legacyStatus !== ConsentStatus.UNKNOWN) {
      setConsentStatus(legacyStatus);
      setLegacyStatus(ConsentStatus.UNKNOWN);
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(STORAGE_KEY);
      }
    }
  }, [crossDomainCookie, legacyStatus, setConsentStatus, setLegacyStatus]);

  const consentStatus = useMemo<ConsentStatus>(() => {
    if (crossDomainCookie !== undefined) {
      return crossDomainCookie === 'true' ? ConsentStatus.CONSENTED : ConsentStatus.NOT_CONSENTED;
    }
    if (legacyStatus !== ConsentStatus.UNKNOWN) {
      return legacyStatus;
    }
    return defaultConsent;
  }, [crossDomainCookie, defaultConsent, legacyStatus, setConsentStatus]);

  return [consentStatus, setConsentStatus] as const;
}
