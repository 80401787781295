import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import createRelayEnvironment from 'relay/createRelayEnvironment';
import { RelayNodeSubscriber } from 'relay/useLiveFragment';
import { useAccessToken } from './AuthenticationProvider/AuthenticationProvider';

const RelayProvider: React.FC<{ locale?: string; children: React.ReactNode }> =
  function RelayProvider({ locale, children }) {
    const getAccessToken = useAccessToken();
    const environment = React.useMemo(() => {
      return createRelayEnvironment({
        getAccessToken,
        onError: (err) => {
          console.warn(err.message);
          if (window.newrelic) window.newrelic.noticeError(err);
        },
        locale: locale,
      });
    }, [getAccessToken, locale]);

    return (
      <RelayEnvironmentProvider environment={environment}>
        <RelayNodeSubscriber />
        {children}
      </RelayEnvironmentProvider>
    );
  };
export default RelayProvider;
