import clsx from 'clsx';
import Footer from 'components/organisms/Footer/Footer';
import Header from 'components/organisms/Header/Header';
import Sidebar from 'components/organisms/Sidebar/Sidebar';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import classes from './PageContainer.module.scss';

export interface Props {
  children?: React.ReactNode;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
}

export default function PageContainer({
  children,
  withoutHeader,
  withoutFooter,
}: Props): JSX.Element {
  const router = useRouter();
  const { theme } = router.query;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // opens the sidebar menu in mobile view
  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };

  // closes the sidebar menu in mobile view
  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div className={clsx(classes.root)}>
      {!withoutHeader && <Header theme={theme} openSidebar={handleOpenSidebar} />}
      <div className={classes.content}>{children}</div>
      {!withoutFooter && <Footer theme={theme} />}
      {!withoutHeader && (
        <Sidebar theme={theme} open={sidebarOpen} closeSidebar={handleCloseSidebar} />
      )}
    </div>
  );
}
