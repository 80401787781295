import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

export default function useQueryStringParams(): Record<string, string> {
  const { query } = useRouter();
  const q: ParsedUrlQuery = query;

  return Object.entries(q).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: Array.isArray(v) ? v[0] : v }),
    {},
  );
}
