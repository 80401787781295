import { AllowedStorageKey } from 'constants/persistentStorageRegistry';
import useStorage from 'hooks/useStorage';
import React from 'react';

export default function useLocalStorageState<T>(
  storageKey: AllowedStorageKey,
  initialState: T | (() => T),
  options: { storeInitialValue?: boolean; disableInstanceSync?: boolean } = {},
): [T, React.Dispatch<React.SetStateAction<T>>] {
  return useStorage(storageKey, initialState, { ...options, session: false });
}
