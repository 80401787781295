import PageConfig from '../templates/Page/PageConfig';

export const DASHBOARD: PageConfig = {
  name: 'My Dashboard',
  path: '/dashboard',
  redirectFrom: ['/'],
  exact: true,
  requiresAuthentication: true,
};

export const LOGIN: PageConfig = {
  name: 'Login',
  path: '/login',
  exact: true,
  requiresAuthentication: false,
};

export const SIGN_UP: PageConfig = {
  name: 'Sign Up',
  path: '/sign-up',
  exact: true,
  requiresAuthentication: false,
};

export const WELCOME: PageConfig = {
  name: 'Landing Page',
  path: '/',
  exact: true,
  requiresAuthentication: false,
};

export const REWARDS: PageConfig = {
  name: 'Rewards',
  path: '/rewards',
  exact: true,
  requiresAuthentication: true,
};
