import clsx from 'clsx';
import A from 'components/atoms/A/A';
import ExternalLink from 'components/atoms/ExternalLink/ExternalLink';
import Txt from 'components/atoms/Txt/Txt';
import {
  AuthenticationContext,
  useIsLoggedIn,
  useUserJwtData,
} from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import useOnePConfiguration from 'hooks/useOnePConfiguration';
import React, { MouseEvent, useContext } from 'react';
import { FaFacebookF, FaInstagram, FaXTwitter } from 'react-icons/fa6';
import {
  MdAccountCircle,
  MdClose,
  MdDataUsage,
  MdExitToApp,
  MdNotifications,
  MdSecurity,
} from 'react-icons/md';
import classes from './Sidebar.module.scss';

export interface Props {
  theme?: string | string[];
  open?: boolean | false;
  closeSidebar?: () => void;
}

const Sidebar: React.FC<Props> = ({ theme, open, closeSidebar }: Props) => {
  const isLoggedIn = useIsLoggedIn();
  const { logOut } = useContext(AuthenticationContext);
  const { country } = useUserJwtData() || { country: '' };
  const isUcmEnabled: boolean = useOnePConfiguration(country, 'ucm_allowed') || false;

  function handleLogout(e: MouseEvent) {
    e.preventDefault();
    logOut('header');
  }

  function handleClose(e: MouseEvent) {
    if (closeSidebar) closeSidebar();
  }

  // If the user is not logged in, the only thing they should be able to do
  // is go to the login page.
  if (!isLoggedIn) return <></>;

  return (
    <div className={clsx(classes.root, theme)} data-open={open}>
      <div className={classes.sidebar}>
        <p className={classes.menuClose}>
          <A
            href={'#'}
            onClick={(e) => {
              e.preventDefault();
              handleClose(e);
            }}
          >
            <MdClose />
          </A>
        </p>
        <nav role="navigation">
          <A href={'/member/account'} onClick={handleClose}>
            <MdAccountCircle />
            &nbsp;<Txt>My Profile</Txt>
          </A>
          <A href={'/member/privacy-settings'} onClick={handleClose}>
            <MdSecurity />
            &nbsp;<Txt>Privacy Settings</Txt>
          </A>
          {isUcmEnabled ? (
            <A href={'/member/data-preferences'} onClick={handleClose}>
              <MdDataUsage />
              &nbsp;<Txt>Data Usage & Preferences</Txt>
            </A>
          ) : (
            <></>
          )}
          <A href={'/member/contact-preferences'} onClick={handleClose}>
            <MdNotifications />
            &nbsp;<Txt>Contact Preferences</Txt>
          </A>

          <ExternalLink link="who" href="#" className={classes.who}>
            <Txt>Who We Are</Txt>
          </ExternalLink>
          <ExternalLink link="members" href="#" className={classes.members}>
            <Txt>Our Members</Txt>
          </ExternalLink>
          <ExternalLink link="how" href="#" className={classes.how}>
            <Txt>How It Works</Txt>
          </ExternalLink>
          <ExternalLink link="terms" href="#" className={classes.terms}>
            <Txt>Membership Terms & Conditions</Txt>
          </ExternalLink>
          <ExternalLink link="privacy" href="#" className={classes.privacy}>
            <Txt>Panel Privacy Policy</Txt>
          </ExternalLink>
          <ExternalLink link="cookies" href="#" className={classes.cookies}>
            <Txt>Cookies Policy</Txt>
          </ExternalLink>
          <ExternalLink link="reward-terms" href="#" className={classes.reward}>
            <Txt>Rewards Program Terms</Txt>
          </ExternalLink>

          <A href={'#'} onClick={handleLogout} className={classes.signout}>
            <MdExitToApp />
            &nbsp;<Txt>Sign Out</Txt>
          </A>
        </nav>
        <div className={classes.connect}>
          <div className={classes.socialMedia}>
            <ExternalLink link="facebook" href="#" rel="noopener" target="_blank">
              <FaFacebookF />
            </ExternalLink>
            <ExternalLink link="twitter" href="#" rel="noopener" target="_blank">
              <FaXTwitter />
            </ExternalLink>
            <ExternalLink link="instagram" href="#" rel="noopener" target="_blank">
              <FaInstagram />
            </ExternalLink>
          </div>
        </div>
      </div>
      <div className={classes.blocker} onClick={handleClose}></div>
    </div>
  );
};

export default Sidebar;
