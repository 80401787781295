// Stub custom hook for provding OneP configuration.
// Can be enhanced once we get an api on the gateway to pull the OneP configuration dynamically.

// Simulate some of the configuration options we can pull from the OneP site.
const CONFIG_OPTIONS = {
  AR: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  AT: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  AU: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  BE: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  BR: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  CA: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  CH: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  CL: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  CN: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: false,
    enabled_social_providers: [],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  CO: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  CZ: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  DE: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  DK: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  ES: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  FI: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  FR: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  GB: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  GR: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  HK: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  HU: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  ID: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  IE: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  IN: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  IT: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  JP: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  KR: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  LB: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: false,
    enabled_social_providers: [],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  MX: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  MY: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  NL: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  NO: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  NZ: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  PE: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  PH: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  PL: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  PT: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  RO: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  SE: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  SG: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  TH: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  TR: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  TW: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 16,
    max_age_limit: 99,
  },
  US: {
    android_app_banner: true,
    ios_app_banner: true,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  VN: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'google', 'apple'],
    min_age_limit: 14,
    max_age_limit: 99,
  },
  ZA: {
    android_app_banner: false,
    ios_app_banner: false,
    ucm_allowed: true,
    social_login_enabled: true,
    enabled_social_providers: ['facebook', 'apple'],
    min_age_limit: 18,
    max_age_limit: 99,
  },
};

export type CountryKey = keyof typeof CONFIG_OPTIONS;
export type ConfigEntry = {
  [key: string]: any;
};

// map of default countries by language, if the country/langauge codes do not match
const LANGUAGE_COUNTRY_MAP = {
  DA: 'DK',
  EN: 'US',
  EL: 'GR',
  HI: 'IN',
  JA: 'JP',
  KO: 'KR',
  MS: 'MY',
  SV: 'SE',
  VI: 'VN',
};
export type LanguageMapKey = keyof typeof LANGUAGE_COUNTRY_MAP;

// Given a OneP config option name and country code, it will return the configuration value or null, in
// cases where the configuration value is not available.
// If a configuration name is not given, it will return the full set of configuration values for the country.
export default function useOnePConfiguration(
  country: string,
  name?: string | string[],
): any | null {
  const countryConfig: ConfigEntry | null =
    CONFIG_OPTIONS[country.toUpperCase() as CountryKey] ||
    CONFIG_OPTIONS[LANGUAGE_COUNTRY_MAP[country.toUpperCase() as LanguageMapKey] as CountryKey];

  if (countryConfig === undefined || countryConfig === null) return null;

  // nested function to get a single value or null
  const getConfigValue = (name: string): any | null => {
    const value = countryConfig[name.toLowerCase()];
    if (value === undefined) return null;
    return value;
  };

  // return an array of values if the name is not a single string value
  if (name === undefined || name === null || name === '') return countryConfig;
  if (Array.isArray(name)) {
    // return the full set for an empty array
    if (name.length == 0) return countryConfig;

    // otherwise map values to names:
    let settings: ConfigEntry = {};
    name.forEach((n: string) => {
      settings[n] = getConfigValue(n);
    });
    return settings;
  }

  // just a singular string value
  return getConfigValue(name as string);
}
