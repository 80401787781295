export const GATEWAY_URL = `${
  process.env.NEXT_PUBLIC_GATEWAY_URL || 'https://api.prod.qurated.ai'
}/graphql`;
export const GATEWAY_SUBSCRIPTION_URL = `${GATEWAY_URL.replace(/^http/, 'ws')}/ws`;
export const AUTH_URL = process.env.NEXT_PUBLIC_AUTH_URL || 'https://login.lifepointspanel.com';
export const AUTH_SESSIONS_URL =
  process.env.NEXT_PUBLIC_AUTH_SESSIONS_URL || 'https://sessions.lifepointspanel.com';
export const QURATED_AUTH_SESSIONS_URL =
  process.env.NEXT_PUBLIC_QURATED_AUTH_SESSIONS_URL || 'https://auth-sessions.prod.qurated.ai';
export const WALLET_URL = process.env.NEXT_PUBLIC_WALLET_URL || 'https://wallet.prod.qurated.ai';
export const SOCIAL_FACEBOOK_LOGIN_URL =
  process.env.NEXT_PUBLIC_SOCIAL_FACEBOOK_LOGIN_URL ||
  'https://login.lifepointspanel.com/users/auth/facebook';
export const SOCIAL_GOOGLE_LOGIN_URL =
  process.env.NEXT_PUBLIC_SOCIAL_GOOGLE_LOGIN_URL ||
  'https://login.lifepointspanel.com/users/auth/google_oauth2';
export const SOCIAL_APPLE_LOGIN_URL =
  process.env.NEXT_PUBLIC_SOCIAL_APPLE_LOGIN_URL ||
  'https://login.lifepointspanel.com/users/auth/apple';
export const HELPCENTER_URL =
  process.env.NEXT_PUBLIC_HELPCENTER_URL || 'https://lifepoints.zendesk.com';
export const HELPCENTER_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_HELPCENTER_AUTH_DOMAIN || 'https://lightspeedresearch.zendesk.com';
