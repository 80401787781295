import clsx from 'clsx';
import Link from 'next/link';
import { ComponentPropsWithRef } from 'react';
import classes from './A.module.scss';

export type Props = ComponentPropsWithRef<typeof Link>;

const A = ({ className, children, rel, target, ...rest }: Props) => {
  const href = rest.href.toString();
  const doNotIncludeRel = href.toString().startsWith('mailto:') || href.startsWith('#');
  const builtRel = `${rel ? `${rel} ` : ''}${target === '_blank' && 'noopener noreferrer'}`;
  return (
    <Link
      className={clsx(className, classes.link)}
      rel={doNotIncludeRel ? undefined : builtRel}
      target={target}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default A;
