import { useRouter } from 'next/router';
import React from 'react';
import { ConversionEvent } from '../ThirdPartyTrackingProvider/trackingScriptEvents';
import { useConversionEventTrigger } from '../ThirdPartyTrackingProvider/TrackingScripts';
import { getUserDataFromToken, submitLoginOtp } from './authApiUtils';
import { UpdateTokens } from './Types';

export function useOtpLogin(handleTokensUpdated: UpdateTokens, isLoggedIn: boolean) {
  const router = useRouter();
  const { pathname, query } = router;

  const conversionEventTrigger = useConversionEventTrigger();
  const handleOtpLogin = React.useCallback(
    (token: string) =>
      submitLoginOtp({ locale: router.locale }, token).then((t) => {
        handleTokensUpdated(t);
        const userData = getUserDataFromToken(t?.accessToken);
        if (query?.doi) {
          conversionEventTrigger(ConversionEvent.CONFIRMED_REGISTRATION, {
            // Even though the hook is automatically loading the user guid it might not be available just yet. It is better to pass it explicitly
            userGuid: userData?.guid,
          });
        }
        return userData;
      }),
    [conversionEventTrigger, handleTokensUpdated, query?.doi, router.locale],
  );
  React.useEffect(() => {
    // Check that the router is ready, as server-side it won't be populated fully
    if (!router.isReady) return;
    let otp = query?.otp || query?.login_token;
    if (otp) {
      // There is an otp/login_token present
      if (typeof otp !== 'string') {
        // if it's not null or a string, it's an array, so utilize the last element.
        otp = otp[otp.length - 1];
      }

      const removeOTP = () => {
        // remove otp/login_token param from url and replace it
        delete router.query.otp;
        delete router.query.login_token;
        router.replace({ pathname, query }, undefined, { shallow: true });
      };
      if (isLoggedIn) {
        // We already have a logged in user. Trying to log the user in again causes some issues. We no longer need the OTP
        removeOTP();
      } else {
        handleOtpLogin(otp as string).then(removeOTP, (reason) => {
          removeOTP();
          console.error(reason?.loginError || reason?.message || reason);
        });
      }
    }
  }, [router.isReady, isLoggedIn]);

  return handleOtpLogin;
}
