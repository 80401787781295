import { AllowedStorageKey } from 'constants/persistentStorageRegistry';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const DEFAULT_OPTIONS: Cookies.CookieAttributes = {
  expires: 365,
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || '.lifepointspanel.com',
  path: '/',
  secure: false,
  sameSite: 'Strict',
};

export default function useCookie(
  cookieName: AllowedStorageKey,
  options?: Cookies.CookieAttributes,
) {
  const [stateValue, setStateValue] = useState<string | undefined>(Cookies.get(cookieName));

  useEffect(() => {
    // If the browser supports the cookieStore API, use it to listen for external changes to the cookie value.
    // If not, fall back to polling the cookie value every 2 seconds.
    if ('cookieStore' in window && typeof window.cookieStore !== 'undefined') {
      const changeHandler = () => {
        setStateValue(Cookies.get(cookieName));
      };
      window.cookieStore.addEventListener('change', changeHandler);
      return () => {
        window.cookieStore!.removeEventListener('change', changeHandler);
      };
    } else {
      const intervalId = window.setInterval(() => {
        setStateValue(Cookies.get(cookieName));
      }, 2000);

      return () => {
        window.clearInterval(intervalId);
      };
    }
  }, [setStateValue, cookieName]);

  useEffect(() => {
    if (stateValue === undefined) {
      Cookies.remove(cookieName, Object.assign({}, DEFAULT_OPTIONS, options));
    } else {
      Cookies.set(cookieName, stateValue, Object.assign({}, DEFAULT_OPTIONS, options));
    }
  }, [cookieName, options, stateValue]);

  return [stateValue, setStateValue] as const;
}
