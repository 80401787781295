import { convertSnakeToCamelCase } from './stringHelper';

export type UserSourceData = {
  inviteToken?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
  city?: string;
  countryCode?: string;
  date?: string;
  fileId?: string;
  fileName?: string;
  mobileCarrier?: string;
  ran?: string;
  referer?: string;
  regionCode?: string;
  source?: string;
  userAgent?: string;
  advertiserId?: string;
  advertiserRef?: string;
  affClickId?: string;
  affId?: string;
  affSub?: string;
  affSub2?: string;
  affSub3?: string;
  affSub4?: string;
  affSub5?: string;
  affUnique1?: string;
  affUnique2?: string;
  affUnique3?: string;
  affUnique4?: string;
  affUnique5?: string;
  affiliateId?: string;
  affiliateName?: string;
  affiliateRef?: string;
  offerFileId?: string;
  offerId?: string;
  offerName?: string;
  offerRef?: string;
  offerUrlId?: string;
  transactionId?: string;
  XPUtmSource?: string;
  XPUtmMedium?: string;
  XPUtmCampaign?: string;
  XPUtmTerm?: string;
  __func?: string;
  contactEmail?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  streetAddress2?: string;
  contactCity?: string;
  state?: string;
  lang?: string;
  country?: string;
  socialToken?: string;
};
const ALLOWED_USER_DATA_PARAMS = [
  'invite',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'city',
  'country_code',
  'date',
  'file_id',
  'file_name',
  'mobile_carrier',
  'ran',
  'referer',
  'region_code',
  'source',
  'user_agent',
  'advertiser_id',
  'advertiser_ref',
  'aff_click_id',
  'aff_id',
  'aff_sub',
  'aff_sub2',
  'aff_sub3',
  'aff_sub4',
  'aff_sub5',
  'aff_unique1',
  'aff_unique2',
  'aff_unique3',
  'aff_unique4',
  'aff_unique5',
  'affiliate_id',
  'affiliate_name',
  'affiliate_ref',
  'offer_file_id',
  'offer_id',
  'offer_name',
  'offer_ref',
  'offer_url_id',
  'transaction_id',
  'transactionId',
  'XP_utm_source',
  'XP_utm_medium',
  'XP_utm_campaign',
  'XP_utm_term',
  '__func',
  'contactEmail',
  'title',
  'firstName',
  'lastName',
  'streetAddress',
  'streetAddress2',
  'contactCity',
  'state',
  'lang',
  'country',
  'socialToken',
];

export function extractUserSourceDataFromQueryString(
  params: Record<string, string | string[] | undefined>,
): UserSourceData {
  return Object.entries(params).reduce((acc, [key, value]) => {
    const newKey = convertSnakeToCamelCase(key) as keyof UserSourceData;
    if (key === 'invite') {
      return {
        ...acc,
        // Remap key
        inviteToken: value,
      };
    } else if (key === '__func') {
      return {
        ...acc,
        //  We don't want to camel case this
        __func: value,
      };
    } else if (!ALLOWED_USER_DATA_PARAMS.includes(key)) {
      return acc;
    } else if (Array.isArray(value)) {
      return {
        ...acc,
        [newKey]: decodeURIComponent(value[0]),
      };
    } else if (value !== undefined) {
      return {
        ...acc,
        [newKey]: decodeURIComponent(value),
      };
    } else {
      return acc;
    }
  }, {});
}
